import React, {useEffect, useCallback} from 'react';
import { RichText } from 'prismic-reactjs';

export default function Modal({modalData, onCloseClick, darkMode}) {
  const { imageData, imageDescription } = modalData;
  const handleClick = () => {
    onCloseClick();
  }
  // console.log(imageDescription)

  useEffect(() => {
    window.addEventListener('keydown', escFunction, false);

    const documentWidth = document.documentElement.clientWidth;
    const windowWidth = window.innerWidth;
    const scrollBarWidth = windowWidth - documentWidth;

    const body = document.body;
    body.style.overflow = 'hidden';
    body.style.paddingRight = `${scrollBarWidth}px`;

    // Cleans up, equivalent to componentWillUnmount
    return function cleanUp() {
      const body = document.body;
      body.style.overflow = null;
      body.style.paddingRight = null;
      window.removeEventListener('keydown', escFunction, false);
    };
  })

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      onCloseClick();
    }
  }, [onCloseClick]);

  return (
    <div className="modal-wrapper">
      <span
        aria-hidden="true"
        className="decorative-close"
        // onClick={handleClick}
      >
        &#215;
      </span>
      <img
        className="modal"
        src={imageData.url}
        alt={imageData.alt}
      />
      {imageDescription && (
        <div className="modal-info">
          <div className="info-icon">?</div>
          {RichText.render(imageDescription)}
        </div>
      )}
      <button onClick={handleClick}>
        <span className="screen-reader-only">Close</span>
      </button>
    </div>
  )
}
