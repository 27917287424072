import React from 'react'

export default function Header({data}) {
  return (
    <>
      <h1 className="animate" style={{ animationDelay: "0s" }}>
        {data.headline[0].text}
      </h1>
      <p className="sub-head animate" style={{ animationDelay: "0.05s" }}>
        {data.text[0].text}
      </p>
      <p className="info animate" style={{ animationDelay: "0.05s" }}>
        {data.info[0].text}
      </p>
    </>
  )
}
