import React from 'react';
import Image from './Image';

export default function Images({data, onImageClick}) {
  const handleImageClick = (url, description) => {
    onImageClick(url, description);
  }

  return (
    <div className="images">
      {data.map((image, index) => {
        if (image.slice_type === 'image_slice') {
          return (
            <Image
              onImageClick={handleImageClick}
              key={`image${index}`}
              image={image.primary}
              index={index}
            />
          )
        }
      })}
    </div>
  )
}
