import React, { useState, useEffect } from 'react';
import Prismic from 'prismic-javascript';
import Header from './Header';
import Images from './Images';
import Loading from './Loading';
import Modal from './Modal';
import './Style/style.sass';

function App() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const apiEndpoint = process.env.REACT_APP_API;
    const accessToken = process.env.REACT_APP_TOKEN;
    const Client = Prismic.client(apiEndpoint, { accessToken })

    const fetchData = async () => {
      try {
        const response = await Client.query(
          Prismic.Predicates.at('document.type', 'text_content')
        )
        if (response) {
          // console.log(response.results[0].data)
          setData(response.results[0].data);
          setLoading(false);
        }
      }
      catch(error) {
        console.warn(`Error:${error}`);
      }
    }

    fetchData();

    const savedMode = JSON.parse(localStorage.getItem('dark'));
    setDarkMode(savedMode || false);

  }, [])

  useEffect(() => {
    localStorage.setItem('dark', JSON.stringify(darkMode));
    document.body.style.backgroundColor = darkMode ? '#202631' : null;
  }, [darkMode])

  const toggleModal = (imageData, imageDescription) => {
    if (!showModal) {
      setModalData({
        imageData: imageData,
        imageDescription: imageDescription.length === 0
          ? null
          : imageDescription,
      });
      setShowModal(true);
    } else {
      setShowModal(false);
      setModalData({
        imageData: null,
        imageDescription: null,
      });
    }
  }

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  }

  return (
    <div className={darkMode ? "wrapper dark" : "wrapper"}>
      <main className="main">
        {loading ? <Loading/> :
          <>
            <Header data={data}/>
            <Images onImageClick={toggleModal} data={data.body}/>
            <button
              className={darkMode ? "dark-mode-button active" : "dark-mode-button"}
              onClick={toggleDarkMode}
              type="checkbox"
            >
              <span className="screen-reader-only">Toggle dark mode</span>
            </button>
          </>
        }
        {showModal && <Modal onCloseClick={toggleModal} modalData={modalData} darkMode={darkMode} />}
      </main>
    </div>
  );
}

export default App;
