import React from 'react';

export default function Image({image, onImageClick, index}) {
  const handleClick = (event) => {
    event.preventDefault();
    onImageClick(image.image, image.image_description);
  }

  return (
    <button
      className="image-box animate"
      onClick={handleClick}
      style={{ animationDelay: `${index * 0.05}s` }}
    >
      <span className="screen-reader-only">Show image in fullscreen</span>
      <img
        className='image'
        src={image.image.url}
        alt={image.image.alt}
      />
      <p className="image-title">{image.title[0].text}</p>
    </button>
  )
}
